/* eslint-disable func-names */
/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';

import { NotificationManager } from '../components/common/react-notifications';
import IntlMessages from '../helpers/IntlMessages';

let conected = window.navigator.onLine;

window.addEventListener('online', function () {
  conected = true;
});

window.addEventListener('offline', function () {
  conected = false;
});

const stage_host = {
  development: {
    url: '127.0.0.1:8000',
  },
  homologation: {
    url: 'hom.api.vemprotribe.com',
  },
  production: {
    url: 'hom.api.vemprotribe.com',
  },
};

const url = `https://${stage_host.homologation.url}`;
export const chat_url = `wss://${stage_host.homologation.url}`;

const api = axios.create({
  baseURL: `${url}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!conected) {
      return Promise.reject('NO_INTERNET');
    }

    if (error?.response && error.response.status === 401) {
      NotificationManager.error(
        /* eslint-disable-next-line react/react-in-jsx-scope */
        <IntlMessages id="generics.sessionExpired" />,
        /* eslint-disable-next-line react/react-in-jsx-scope */
        <IntlMessages id="generics.error" />,
        7500,
        null,
        null,
        ''
      );
    }

    return Promise.reject(error);
  }
);
api.authURL = `${url}/api/v1/api-token-auth/admin/`;
api.authRefreshURL = `${url}/api/v1/api-token-refresh/`;
api.url = url;

export default api;
